import { Text } from "@clipboard-health/ui-react";
import { Box, Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { GRAY_82 } from "@src/appV2/lib/Theme/constants";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import pluralize from "pluralize";

import { type OpenShiftsResponse } from "../api/useOpenShifts";
import { bookedShiftColor, priorityAccessColor, shiftPeriodColors } from "../constants";
import { type OnShiftPeriodClickProps } from "./types";

interface GetShiftColorProps {
  hasBookedShift: boolean;
  hasOpenShifts: boolean;
  hasPriorityShift: boolean;
  shiftLabel: ShiftPeriod;
}

function getShiftColors(props: GetShiftColorProps) {
  const { hasBookedShift, hasOpenShifts, hasPriorityShift, shiftLabel } = props;
  if (hasBookedShift) {
    return {
      iconColor: bookedShiftColor,
      textColor: bookedShiftColor,
    };
  }

  if (hasPriorityShift) {
    return {
      iconColor: priorityAccessColor,
      textColor: priorityAccessColor,
    };
  }

  if (hasOpenShifts) {
    const { color: shiftPeriodColor } = shiftPeriodColors[shiftLabel];
    return {
      iconColor: shiftPeriodColor,
      textColor: shiftPeriodColor,
    };
  }

  return {
    iconColor: GRAY_82,
    textColor: GRAY_82,
  };
}

const shiftPeriodBookedShiftLabels = {
  [ShiftPeriod.AM]: "You are working an AM shift",
  [ShiftPeriod.PM]: "You are working a PM shift",
  [ShiftPeriod.NOC]: "You are working an NOC shift",
};

interface ShiftPeriodCardProps {
  hasBookedShift: boolean;
  priorityShiftCount: number;
  Icon: React.ElementType;
  isActive: boolean;
  label: ShiftPeriod;
  openShifts: OpenShiftsResponse["openAgentShifts"];
  shiftFriends: Array<{ name: string }>;
  onClick: (props: OnShiftPeriodClickProps) => void;
}

export function ShiftPeriodCard(props: ShiftPeriodCardProps) {
  const {
    label,
    isActive,
    Icon,
    openShifts,
    hasBookedShift,
    priorityShiftCount,
    shiftFriends,
    onClick,
  } = props;

  const { iconColor, textColor } = getShiftColors({
    hasBookedShift,
    hasOpenShifts: openShifts.length > 0,
    hasPriorityShift: priorityShiftCount > 0,
    shiftLabel: label,
  });

  return (
    <Card variant="outlined">
      <CardActionArea
        disabled={!isActive}
        sx={{ paddingY: 2 }}
        onClick={() => {
          onClick({ shiftPeriod: label, isActive });
        }}
      >
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={4}>
            <Icon
              sx={{
                color: iconColor,
                fontSize: "2.5rem",
              }}
            />
            <Box>
              <Text
                bold
                sx={{
                  color: textColor,
                  paddingBottom: 1,
                }}
              >
                {label.toUpperCase()}
              </Text>

              {hasBookedShift ? (
                <Text>{shiftPeriodBookedShiftLabels[label]}</Text>
              ) : (
                <Text>
                  {openShifts.length > 0
                    ? `${openShifts.length} open ${label.toUpperCase()} ${pluralize(
                        "shift",
                        openShifts.length
                      )}`
                    : "No open shifts"}{" "}
                  {priorityShiftCount > 0 && <span>({priorityShiftCount} priority)</span>}
                </Text>
              )}

              {shiftFriends.length > 0 ? (
                <Text variant="body2" sx={{ paddingTop: 1 }}>
                  {shiftFriends.length === 1
                    ? `${shiftFriends[0].name} booked a shift`
                    : `${shiftFriends.length} friends booked shifts`}
                </Text>
              ) : null}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
